<template lang="pug">
div
  .d-flex.step-holder
    stepper(:activeStep="activeContent" @stepClick="validateClickedStep" :steps="alignedSteps")
  .content
    transition(name="fade" mode="out-in")
      step1(
        v-if="this.activeContent === 'setup'"
        :settings.sync="settings"
        :validations="$v.settings"
        :validByAlert.sync="validByAlert"
      )
      step2(
        v-if="this.activeContent === 'listId'"
        :settings.sync="settings"
        :validations="$v.settings"
        :validByAlert.sync="validByAlert"
        @isModified="step2Modified = $event"
      )
      step3(
        v-if="this.activeContent === 'fieldMapping'"
        ref="bindings"
        :state="state"
        :settings.sync="settings"
        :validByAlert.sync="validByAlert"
        @isModified="isBindingModified = $event"
      )
  integration-modal-footer(
    :activeStep="activeContent"
    :steps="alignedSteps"
    :isButtonActive="isValid"
    :alertCode="alertCode"
    :helpLink="$t('integrationFlow.mailChimp.helpUrl')"
    :isIntegrationModified="[isBindingModified, step2Modified]"
    @click="handleButtonClick"
  )
</template>
<script>
  import { required, requiredIf } from 'vuelidate/lib/validators';
  import Stepper from '@/components/Stepper/index.vue';
  import integrationBase from '@/mixins/integration/integrationBase';
  import IntegrationModalFooter from '../IntegrationModalFooter.vue';
  import Step1 from './step1.vue';
  import Step2 from './step2.vue';
  import Step3 from './step3.vue';

  export default {
    components: {
      IntegrationModalFooter,
      Stepper,
      Step1,
      Step2,
      Step3,
    },

    mixins: [integrationBase],

    data() {
      return {
        activeContent: 'setup',
        integrationType: 'mailChimp',
      };
    },

    validations() {
      return {
        settings: {
          apiKey: { required, validByAlert: () => this.validByAlert.apiKey },
          name: { required },
          convertedSettings: {
            listId: {
              required: requiredIf(function () {
                return !this.isFirstStep;
              }),
              validByAlert: () => this.validByAlert.list,
            },
          },
        },
      };
    },
  };
</script>
