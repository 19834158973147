<template lang="pug">
.content-step2
  om-select#listName.mb-3(
    v-model="listId"
    :options="lists"
    :label="$t('integrationFlow.integrationModal.general.listHelper', { type: 'MailChimp' })"
    :placeholder="$t('integrationFlow.integrationModal.selectAList')"
    :error="isListInvalidByAlert"
    @input="onListChange"
  )
  #listName.error-msg.mt-3(v-if="isListInvalidByAlert" key="error-message")
    span.text-danger(v-html="$t('integrationFlow.integrationModal.general.invalidList')")
  om-switch#doi.doi-switch.mb-2(
    v-model.sync="doubleOptin"
    :label="$t('integrationFlow.mailChimp.doi')"
  )
  .doi-info {{ $t('integrationFlow.doiInfo') }}
  .error-msg.mt-3(v-if="hasError")
    span.text-danger(v-html="$t(`integrationFlow.mailChimp.notifications.apiConnectError`)")
</template>
<script>
  import { IntegrationService } from '@/services/integrations/integrationService';
  import validationByAlert from '@/mixins/integration/validationByAlert';
  import trackStep2Mixin from '@/mixins/integration/trackStep2';

  export default {
    mixins: [validationByAlert, trackStep2Mixin],
    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        step2Fields: ['listId', 'doubleOptin'],
        lists: [],
        hasError: false,
      };
    },

    computed: {
      listId: {
        get() {
          const key = this.settings.convertedSettings?.listId;

          // om-select selected option only needs "key" from object
          return key ? { key } : null;
        },
        set(option) {
          this.updateListSetting(option?.key);
        },
      },

      doubleOptin: {
        get() {
          return this.settings.convertedSettings?.doubleOptin || false;
        },
        set(value) {
          const settings = this.settings;
          settings.convertedSettings.doubleOptin = value;
          this.$emit('update:settings', { ...settings });
          this.emitIsModified();
        },
      },
    },

    async mounted() {
      // IMPORTANT to load data then set model (behavior of om-select)
      const lists = await this.loadLists();

      if (!lists.length) {
        this.updateListSetting(null);
        return;
      }

      this.lists = lists;

      this.listId = lists.find(({ key }) => key === this.settings.convertedSettings.listId);

      this.storeInitialStep2State();
    },

    methods: {
      async loadLists() {
        const integrationService = new IntegrationService(this.$apollo);
        let lists = [];

        try {
          const result = await integrationService.fetchIntegrationLists(
            this.settings.type,
            this.settings.id,
          );

          if (result.lists?.length) {
            lists = result.lists.map((list) => ({
              key: list.id,
              value: list.name,
            }));
          } else {
            this.errorNotification(result.error);
          }
        } catch (e) {
          this.errorNotification(e.message);
        }

        return lists;
      },
      errorNotification(message) {
        this.hasError = true;
        this.$notify({
          type: 'error',
          text: message,
        });
      },

      updateListSetting(value) {
        const settings = this.settings;
        settings.convertedSettings.listId = value;
        this.$emit('update:settings', { ...settings });
        this.emitIsModified();
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .doi-switch
    width: 170px
    margin-left: 0
    ::v-deep .switch-label
      margin-top: 8px
  .doi-info
    font-size: 12px
    color: $om-gray-600
</style>
