<template lang="pug">
.step-field-mapping
  loading-logo(v-if="loading")
  template(v-else)
    .d-flex.fields-wrapper.w-100
      .title.mb-1(v-html="$t('integrationFlow.mailChimp.chooseGroups')")
      .d-flex.groups
        multiselect(
          :options="groups"
          v-model="selectedGroups"
          :clearOnSelect="false"
          :multiple="true"
          :taggable="false"
          track-by="id"
          label="value"
          :selectedLabel="$t('multiselect.labels.selected')"
          :deselectLabel="$t('multiselect.labels.deselect')"
          :placeholder="$t('integrationFlow.mailChimp.groupMultiselectPlaceholder')"
        )
          span(slot="noOptions") {{ $t('multiselect.slot.noOptions') }}
    .title.mt-5 {{ $t('integrationFlow.integrationModal.general.fieldMappingAltTitle', { type: 'MailChimp' }) }}
    .d-flex.fields-wrapper.w-100
      om-toast.mb-6(v-if="!validByAlert.fields" color="danger" :closable="false" type="side") {{ $t('integrationFlow.integrationModal.general.checkFields', { integration: 'MailChimp' }) }}
      om-toast.mb-6(
        v-if="reqFields.length"
        :color="this.missingRequiredFields.length ? 'danger' : 'warning'"
        :closable="false"
        type="side"
      )
        div(v-html="requiredFieldsMessage")
      .d-flex.fields-title
        .om-fields-header {{ $t('integrationFlow.integrationModal.general.omInputFields') }}
        .arrow-block
        .integration-fields-header {{ $t('integrationFlow.integrationModal.general.integrationFields', { type: 'MailChimp' }) }}
      .d-flex.fields(v-for="(binding, index) in formBindings")
        binding-row.w-100.justify-content-between(
          :binding="binding"
          :index="index"
          :fields="fields"
          :type="settings.type"
          :groupNames="groupNames"
          :isNewFlow="true"
          :hasError="bindingRowHasError(binding)"
        )
    .d-flex.additional-data
      .d-flex.additional-data-row
        label.d-flex(for="additionalDataCheckbox")
          input#additionalDataCheckbox(type="checkbox" v-model="checkboxValue")
          .additional-data-text {{ $t('integrationFlow.integrationModal.general.additionalData') }}
      .d-flex.additional-data-row
        .additional-data-helper {{ $t('integrationFlow.integrationModal.general.additionalDataHelper') }}
    transition(name="fade")
      .d-flex.other-fields(v-if="checkboxValue")
        .d-flex.fields-wrapper.w-100
          .d-flex.fields-title
            .om-fields-header {{ $t('integrationFlow.integrationModal.general.customValue') }}
            .arrow-block
            .integration-fields-header {{ $t('integrationFlow.integrationModal.general.integrationFields', { type: 'MailChimp' }) }}
          .d-flex.fields(v-for="(binding, index) in campaignDataBindings")
            .om-campaign-additional-fields.w-100
              binding-row.w-100.justify-content-between(
                :binding="binding"
                :index="index"
                :fields="fields"
                :type="settings.type"
                :isNewFlow="true"
                :groupNames="groupNames"
              )
          .d-flex.fields(
            v-if="staticBindings.length > 0"
            v-for="(binding, index) in staticBindings"
          )
            .om-campaign-static-fields.w-100
              binding-row.w-100.justify-content-between(
                :binding="binding"
                :index="index"
                :fields="fields"
                :isNewFlow="true"
                :type="settings.type"
                @removeStaticRow="deleteStaticField(index)"
              )
          .add-fields
            .d-flex.cursor-pointer(@click="addStaticField")
              om-button(icon="plusCircle" iconSize="1.715em" iconOnly style="color: #ed5a29")
              .font-size-0--875.font-weight-semibold.line-height-1.align-self-center {{ $t('integrationFlow.integrationModal.general.addCustomValue') }}
</template>
<script>
  import BindingRow from '@/components/IntegrationBindings/Row.vue';
  import { UilArrowRight } from '@iconscout/vue-unicons';
  import bindingMixin from '@/mixins/integration/binding';
  import { MailChimpIntegrationService } from '@/services/integrations/mailChimpIntegrationService';

  export default {
    components: {
      UilArrowRight,
      BindingRow,
    },

    mixins: [bindingMixin],

    props: {
      validByAlert: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        loading: false,
        fields: [],
        groups: [],
        groupNames: [],
        reqFields: [],
      };
    },
    computed: {
      selectedGroups: {
        get() {
          return this.settings.convertedSettings?.hiddenListGroupId || [];
        },
        set(value) {
          const settings = this.settings;
          settings.convertedSettings.hiddenListGroupId = value;
          this.$emit('update:settings', { ...settings });
        },
      },
      requiredFieldsMessage() {
        return `${this.$t('integrationBindingErrorMessages.requiredFieldsInList', {
          integration: 'MailChimp',
          fieldList: this.requiredFieldNames.map((field) => this.$t(field)).join(', '),
        })}`;
      },
      requiredFieldNames() {
        return this.reqFields.map((reqField) => {
          const field = this.fields.find((field) => field.id === reqField);
          return field.name;
        });
      },
    },

    async mounted() {
      this.$on('isBindingModified', (value) => this.$emit('isModified', value));

      this.loading = true;
      const result = await this.loadFields();

      this.fields = result.fields;
      this.groups = result.groups;
      this.groupNames = result.groupNames;
      this.reqFields = result.reqFields;
      this.loading = false;
    },

    methods: {
      validateBindings() {
        const assignedFields = [];
        this.settings.bindings.forEach((binding) => {
          if (binding.externalId) assignedFields.push(binding.externalId);
        });

        const isValid = this.reqFields.every((reqField) => assignedFields.includes(reqField));

        if (!isValid) {
          this.missingRequiredFields = [...this.requiredFieldNames];
        }

        return isValid;
      },
      async loadFields() {
        const mailChimpIntegrationService = new MailChimpIntegrationService(this.$apollo);
        const data = { groups: [], groupNames: [], reqFields: [], fields: [] };

        try {
          const result = await mailChimpIntegrationService.fetchMailChimpGroupsAndFields(
            this.settings.type,
            this.settings.id,
            this.settings.convertedSettings.listId,
          );

          if (!result.mailChimpData) {
            return;
          }

          data.fields = result.mailChimpData.fields.sort((field1, field2) => {
            return field1.name > field2.name ? 1 : -1;
          });
          data.groups = result.mailChimpData.groups;
          data.groupNames = result.mailChimpData.groupNames;
          data.reqFields = result.mailChimpData.reqFields;
        } catch (e) {
          this.errorNotification(e.message);
        }

        return data;
      },

      errorNotification(message) {
        this.hasError = true;
        this.$notify({
          type: 'error',
          text: message,
        });
      },
    },
  };
</script>
